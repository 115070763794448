
.buttonWrapper {
  display: flex;
  gap: 30px;
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  @media (max-width: 768px) {
    gap: 15px;
  }
}

.buttonWrapper > button {
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.button {
  border: 2px solid #856AAE !important;
  background-color: transparent !important;
} 

.button:hover {
  background-color: transparent !important;
}
