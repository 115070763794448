.universe .hero-content {
  padding-left: 10%;
}

.universe .hero-content h1 {
  font-size: 48px;
  line-height: 93.5%;
}

.universe .hero-content p.subgraph {
  font-size: 16px;
  line-height: 24px;
  color: #eef3fa;
}

#universe-armorite {
  display: initial;
  position: absolute;
  top: -100px;
  left: -170px;
}

@media only screen and (max-width: 1080px) {
  .universe-inner {
    padding: 0 !important;
  }

  .universe .hero-content {
    padding: 0px 10% !important;
  }

  #universe-armorite {
    display: none;
  }
}

.universe {
  position: relaive;

  .universe-container {
    height: 820px;
    width: 100%;
    max-width: 1920px;
    padding: 0;

    @media (min-width: 1080px) {
      height: 1070px;
      padding: 103px 114px;
    }

    .universe-map {
      margin-top: 35px;
      width: 100%;
      height: 1070px;
      position: absolute;
      top: 0;
      right: 0;
      background: url('../../../images/sections/universe/new-map.png');
      background-repeat: no-repeat;
    }

    .content {
      position: relative;

      .universe-header {
        display: flex;
        flex-direction: column;
        justify-content: center;

        background: linear-gradient(
            118.92deg,
            rgba(237, 90, 143, 0.6) 1.07%,
            rgba(82, 126, 239, 0.6) 99.61%
          ),
          rgba(0, 0, 0, 0.65);
        box-shadow: 9px 7px 60px #0c1516, 9px 19px 33px rgba(0, 0, 0, 0.65),
          inset 1px 1px 0px rgba(255, 255, 255, 0.12);
        border-radius: 15px;

        width: 467px;
        height: 201px;
        padding: 22px 36px 0 41px;
        margin-bottom: 31px;
        text-align: left;

        h3 {
          margin: 0 0 12px;
          text-transform: uppercase;
          font-size: 40px;
          font-weight: 600;
          line-height: 37px;
        }

        p {
          font-size: 18px;
          font-weight: 300;
          line-height: 30px;
        }
      }
    }
  }
}

.universe {
  .gps-marker {
    position: absolute;
    border-radius: 50%;

    @keyframes GLOW {
      from {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
      }
      50% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4),
          inset 0 0 0 10px (rgba(255, 255, 255, 0.4));
      }
      70% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4),
          inset 0 0 0 10px (rgba(255, 255, 255, 0.4));
      }
      to {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
      }
    }

    animation-name: GLOW;
    animation-timing-function: linear;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
  }
}
