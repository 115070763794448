.topbar-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 64px;
    height: 80px;
    align-items: center;
    background-image: url('../../../../assets/images/BGTexture.png');
    background-position: bottom;
    background-size: cover;

    @media all and (max-width: 768px) {
        padding: 0 15px;        
    }
}

.topbar-goback {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    font-family: 'Kanit';
}

.topbar-title {
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    margin: 0;
}

.topbar-page {
    font-size: 20;
    color: '#9E9E9E';
}

.topbar-switch {
    cursor: pointer;
    font-size: 16px;
    font-family: 'Kanit'
}

.container {
    margin: 30px auto;

    .controls {
        display: flex;
        justify-content: space-between;
        width: 99%;
        margin-bottom: 20px;

        @media only screen and (max-width: 768px) {
            justify-content: space-around;
        }

        button {
            cursor: pointer;
            color: white;
            background-color: transparent;
            border: 1px solid #FBFBFB;
            border-radius: 7px;
            padding: 16px 30px;
            gap: 10px;
            font-size: 14px;
            font-weight: 600;

            @media only screen and (max-width: 1024px) {
                padding: 15px;
            }
        }

        button:nth-child(3) {
            background-color: #308DE2;
            border-color: #1E6AAF;
        }

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 1.5;

            span {
                font-size: 14px;
                color: #FBFBFB;
                font-weight: normal;
            }
        }
    }

    .zoom-controls {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        div {
            cursor: pointer;
            color: white;
            background-color: transparent;
            margin: 10px;
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
        }
    }

    .pdfViewerDocument {
        display: flex;
        justify-content: center;

        &.flex-column {
          flex-direction: column;
        }

        .pdfViewerPage {
            overflow: auto;

            @media only screen and (max-width: 1024px) {

                canvas,
                div {
                    width: 100% !important;
                    height: auto !important;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .placeholder {
        width: 100%;
        height: 100%;

        .header {
            width: 100%;
            display: flex;
            align-items: center;

            @media all and (max-width: 768px) {
                flex-direction: column;
                padding-left: 16px;
                padding-right: 16px;
            }
            .avatar {
                width: 208px;
                height: 195px;
            }
    
            .title {
                font-family: 'Kanit';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                line-height: 113.5%;
                text-transform: uppercase;
                color: #FFFFFF;
                
                @media all and (min-width: 768px) {
                    width: 336px;
                    margin-left: 45px;
                }

                @media all and (max-width: 768px) {
                    margin: 0;
                    padding-top: 51px;
                    text-align: left;
                    font-weight: 700;
                    font-size: 31px;
                    line-height: 125.5%;                    
                }
            }
        }
        
        .footer {
            margin-top: 64px;
            font-family: 'Kanit';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 31px;
            color: #D9D9D9;

            @media all and (max-width: 768px) {
                text-align: left;
                margin: 16px;
            }
        }
    }
}
