.swiper-button-prev {
  width: 124px;
  height: 124px;
  background-image: url('../../../images/carousel-arrow.png');
  background-size: contain;
  transform:  rotate(180deg);
  left: -70px;

  &::after {
    content: none;
  }

  @media (max-width: 768px) {
    background-image: url('../../../images/carousel-arrow-mobile.png');
    transform: rotate(0deg);
    background-repeat: no-repeat;
    left: 0;
    top: 500px;
  }
}

.swiper-button-next {
  width: 124px;
  height: 124px;
  background-image: url('../../../images/carousel-arrow.png');
  background-size: contain;
  right: -70px;

  &::after {
    content: none;
  }

  @media (max-width: 768px) {
    background-image: url('../../../images/carousel-arrow-mobile.png');
    transform: rotate(180deg);
    background-repeat: no-repeat;
    right: 0;
    top: 500px;
  }
}

.absolute-div {
  width: 408px;
  padding: 28px 42px;
  font-weight: 500;
  font-size: 32px;
  line-height: 85.5%;
  text-transform: uppercase;
  position: absolute;
  top: 88px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 222;
}

.absolute-arrow {
  position: absolute;
  right: 40px;
  top: 30px;
  z-index: 120;
}

.absolute-text {
  margin: 0;
  padding: 0; 
  font-size: 32px;
  font-weight: 500;
  line-height: 85.5%;
  letter-spacing: normal;
  text-transform: uppercase;
}
.claim-bg-fade {
  
  background: linear-gradient(180deg, rgba(17, 18, 25, 0.69) 0%, rgba(0, 0, 0, 1) 100%);
}
.section-title {
  padding-top: 120px;
  padding-bottom: 64px;
  h1 {
    margin: 0;
    text-align: center;
    height: 27px;
    left: 624.5px;
    top: 116.85px;

    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 27px;
    text-transform: uppercase;

    color: #FFFFFF;
  }
}
.hero {
  // background: radial-gradient(126.75% 88.22% at 0% 4.47%, #CBCDF3 0%, #7870A6 30.97%, #32285A 49.23%, #1D183C 66.77%, #181431 87.87%, rgba(20, 20, 49, 0.68) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  mix-blend-mode: normal;
  transition: all 1s;

  background-image: url("../../../images/sections/hero/BGHero-CostracaMobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  @media screen and (min-width: 475px) {
  background-image: url("../../../images/sections/hero/BGHero-Costraca.png");
  }
}

.hero .hero-top {
  padding-top: 0px;
  background-color: #111219B2;
}

.hero .hero-top p {
  max-width: 500px;
}
.claim-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 33px;
  padding: 0 125px;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 1200px) {
    padding: 0 90px;
  }
  @media (max-width: 1080px) {
  }
  @media (max-width: 768px) {
    padding: 0 60px;
  }
  @media (max-width: 500px) {
    padding: 0 15px;
  }
}
.hero-inner {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 0px;
}
.left-button-group {
  justify-content: left !important;
}

.hero-figure {
  flex: 50%;
  margin-top: 0px;
  padding-right: 180px;
}

.illustration-element-01 {
  margin-top: 4rem;
}

.hero-figure {
  padding: 0 0 0 1rem;
}

.hero-figure img {
  width: 80%;
  height: auto;
  //box-shadow: inset -80px -46px 184px rgba(7, 4, 22, 0.2);
  // filter: drop-shadow(-70px 154px 155px rgba(0, 0, 0, 0.61));
}

.hero-content {
  flex: 40%;
  @media all and (max-width: 768px) {
    width: 90%;
  }

}

.hero-content h3 {
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 89.5%;
}

.hero-content .buttons {

  > a:nth-child(2) {
    margin-left: 10px;
    background-color: transparent;
    border: 1px solid #b890f4;
  }
}

.hero-content h1 {
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
  line-height: 89.5%;
}

.hero-content p {
  font-size: 20px;
  line-height: 31px;
}

.hero-bottom {
  padding: 1.5rem 1rem;
}

.hero-bottom .hero-inner {
  padding-bottom: 30px;
  max-width: 388px;
  margin: auto;

  @media (min-width: 768px) {
    max-width: unset;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.hero-bottom .hero-inner h4 {
  font-size: 45px;
  line-height: 125.5%;
}

.hero-bottom .hero-inner p {
  font-weight: 300;
  font-size: 26px;
  line-height: 125.5%;

  color: #d9d9d9;
}

.hero-bottom .hero-inner .hero-figure {
  padding-left: 0;
  width: fit-content;
  flex: 40% 1;

  img {
    margin-right: 0;
    margin-left: 0;
  }
}

.hero-bottom .hero-inner .input-group {
  display: flex;
}

.hero-bottom .hero-inner .input-group form {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
}

.hero-bottom .hero-inner .input-group input {
  width: 150%;
  background-color: transparent;
  border: 1px solid #b890f4;
  border-width: 0px 0px 1px 0px;
  color: #b890f4;
}

.hero-bottom .hero-inner .input-group input::placeholder {
  color: #b890f4;
}

.hero-bottom .hero-inner .input-group button {
  background-color: transparent;
  border: 1px solid #b890f4;
  width: 100%;
  margin: 1rem 0 0 0;
  font-weight: 400;
}

.hero-bottom .hero-inner img {
  min-width: 240px;
  max-width: 685px;
}

  /** Comig book **/
.comic-book-cover-desktop{
  display: none;
}

.comic-book-cover-mobile {
  display: initial;
}

.comic-book-cover-mobile-container{
  background-image: url("../../../images/sections/hero/Light_rays1.png");
}

.hero-top p {
  padding-left: 1rem;
  padding-right: 1rem;
}

.launch-drop {
  text-align: center;
  margin-left: auto;
  width: fit-content;

  @media screen and (max-width: 768px) {
    padding-right: 15px;
    > div {
      flex-wrap: wrap;
    > a.button{
      width: 100%;
      margin-left: 0px !important;
      margin-top: 15px;
    }
  }
}
}


@media screen and (min-width: 992px) {

  .hero-top p {
    padding-left: 0rem;
    padding-right: 1rem;
  }
  .hero-figure {
    flex: 50%;
    margin-top: 0px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .hero-bottom {
    padding-left: 50px;
    padding-right: 50px;
  }

  .hero-bottom .hero-inner {
    max-width: 70%;
    margin: auto;

    p {
      margin-bottom: 2rem;
    }
  }

  .hero-content {
    flex: 40%;
  }

  .hero-bottom .hero-inner .input-group form {
    flex-wrap: nowrap;
    // padding-right: 20%;
  }

  .hero-bottom .hero-inner .input-group button {
    background-color: transparent;
    border: 1px solid #b890f4;
    margin: 0 0 0 5px;
    font-weight: 400;
  }

  .hero-bottom .hero-inner .input-group input {
    min-width: 300px;
    background-color: transparent;
    border: 1px solid #b890f4;
    border-width: 0px 0px 1px 0px;
    color: #b890f4;
  }

  /** Comig book **/
  .comic-book-cover-desktop{
    display: initial;
  }

  .comic-book-cover-mobile {
    display: none;
  }
}

.mint-popup {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: fit-content;
  max-width: 1000px;
  background-color: #302F43;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index: 10000;
  max-height: 90vh;
  @media all and (max-width: 768px) {
    overflow-y: auto;
    top: 100px !important;
  }
  @media all and (max-width: 480px) {
    top: 25px !important;
  }
  .loading-wrapper {
    height: 30vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mint-popup-header {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0 50px;
    @media all and (max-width: 768px) {
      padding: 50px 30px 0 25px;
    }
    .back-button {
      cursor: pointer;
      align-self: center;
      color: #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 15px;
      }
    }
    .close-button {
      cursor: pointer;
      display: flex;
      align-self: end;
      width: 25px;
      height: 25px;
      min-height: 25px;
      background: url('/assets/images/close.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .content {
    padding-right: 2rem;
    letter-spacing: 1px;
    padding: 20px 80px 80px 101px;
    display: flex;
    gap: 90px;
    @media (max-width: 1080px) {
      flex-direction: column;
      gap: 0;
      padding: 20px 30px 40px 30px;
      justify-content: flex-start;
      align-items: center;
      overflow-x: hidden;
      overflow-y: auto;
    }
    @media (max-width: 768px) {
      padding: 20px 30px 40px 30px;
    }
    .left-content {
      flex-basis: 40%;
      width: 40%;
      display: flex;
      flex-direction: column-reverse;
      position: relative;
      img {
        position: relative;
        bottom: -10%;
      }
      @media (max-width: 1080px) {
        width: 40%;
      }
      @media (max-width: 768px) {
        width: 200px;
        img {
          width: 100%;
        }
      }
    }
    .right-content {
      width: 60%;
      @media (max-width: 1080px) {
        width: 100%;
      }
    }
    .title {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1;
      @media all and (min-width: 768px) {
        font-size: 2rem;
      }
    }
    .text-content {
      @media all and (min-width: 768px) {
        width: 100%;
      }
    }
    .description {
      color: #FFFFFF;
      font-size: .8rem;
      line-height: .8rem;
      font-weight: normal;
      padding-top: .5rem;
      @media all and (min-width: 768px) {
        font-size: 1rem;
        line-height: 1rem;
      }
    }

    form {
      margin-top: 20px;
      width: 100%;
      @media all and (max-width: 768px) {
        width: 100%;
      }
      .submit-message {
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 300;
        font-size: 19px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        width: fit-content;
        margin-top: 13px;
        .green-check {
          width: 28px;
          height: 28px;
          border-radius: 999px;
          background: #30A450;
          margin-left: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 14px;
          }
        }
      }
      .recaptcha {
        height: 0;
        width: 0;
        overflow: hidden;
      }
      .button-wrapper {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        @media all and (max-width: 768px) {
          gap: 15px;
        }
        .create-wallet {
          margin: 0;
        }
        &.mobile-wrap {
          align-items: flex-start;
          flex-direction: column;
          gap: 0;
          button {
            margin-bottom: 20px;
          }
        }
        button {
          margin-top: 0;
        }
      }
      input,select {
        min-width: 300px;
        background-color: transparent;
        border: 1px solid #b890f4;
        border-width: 0px 0px 1px 0px;
        color: #b890f4;
        padding-left: 0px;
        @media all and (max-width: 768px) {
          min-width: unset;
          width: 100%;
        }
      }
      input::placeholder {
        color: #b890f4;
      }
      span {
        font-size: 14px;
      }
      button{
        background-color: transparent;
        border: 1px solid #b890f4;
        
        font-weight: 400;
        // width: 115px;
        margin-top: 20px;
      }
      .grecaptcha-badge {
        position: relative !important;
        margin-top: 40px !important;
        margin-bottom: 20px !important;
        bottom: 0 !important;
        left: 0;
        visibility: visible !important;
      }

      .typeSelect{
        background-color: transparent;
        color: #b890f4;
        width: 100%;
        border: 0;
        margin-top: 15px;
        margin-left: 0;
        padding-left: 0;
      }
    }

    .bottom-content {
      display: flex;
      .left-content {
        flex: 40%;
        padding-left: 3rem;
        padding-bottom: 1rem;
        img {
          float:left;
          margin-top: 0px;
          @media all and (max-width: 768px) {
            width: 90%;
            float: none;
          }
        }
        @media all and (max-width: 768px) {
          padding-left: 1rem;
        }
      }
      .right-content {
        flex: 60%;
      }
      .create-wallet {
        margin-top: 35px;
        font-size: 20px;
        font-weight: 300;
        width: 100%;
        display: block;
        min-width: 300px;
        a {
          text-decoration: underline;
          color: #65B5FF;
          &:hover {
            color: #308DE2;
          }
          
        }
      }
    }
    @media all and (max-width: 768px) {
      width: 100%;
      margin-top: 10px;
    }

  }
  .footer {
    overflow: hidden;
    background-color: #242335;
    border-radius: 0 0 12px 12px;
    padding: 42px;
    text-align: center;
    @media screen and (max-width: 768px) {
      padding: 30px;
      padding-bottom: 50px;
    }
    p {
      margin: 0;
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 300;
      font-size: 17px;
      line-height: 21px;
      @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 17px;
      }
    }
    a {
      text-decoration: underline;
      color: #65B5FF;
      &:hover {
        color: #308DE2;
      }
    }
  }
  @media all and (max-width: 768px) {
    width: 90%;
    top: 15%;
  }

}

.button-coming-soon {
  span {
    &:nth-child(2){
      display: none;
    }
  }
  &:hover {
    span {
      &:nth-child(2){
        display: block;
      }
      &:nth-child(1){
        display: none;
      }
    }
  }
}

.hero-carousel {
  // .comic-dropdown {
  //   margin: 36px auto 54px;
  //   width: 100%;

  //   h3 {
  //     width: fit-content;
  //     font-size: 32px;
  //     font-weight: 500;
  //     padding-bottom: 15px;
  //     border-bottom: 2px solid #8A8A8A;
  //     text-transform: uppercase;
  //     margin: 0 auto;
  //     line-height: 27px;
  //   }
  // }
  height: 100%;

  .hero-carousel-item {
    height: 100%;

    .hero-carousel-item-inner {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 475px) {
        flex-direction: column;
      }
      gap: 30px;
      // flex-wrap: wrap;
      // height: 702px;
      margin: auto;
      padding: 35px 45px 39px 37px;
      background: linear-gradient(118.92deg, rgba(237, 90, 143, 0.38) 1.07%, rgba(82, 126, 239, 0.38) 67.28%), rgba(0, 0, 0, 0.8);
      box-shadow: 9px 7px 60px rgba(12, 21, 22, 0.44), 9px 19px 33px rgba(0, 0, 0, 0.5), 3px 3px 1px rgba(0, 0, 0, 0.5), inset 1px 1px 0px rgba(255, 255, 255, 0.12);
      border-radius: 15px;
      text-align: left;
      position: relative;
      overflow: hidden;
      &-left{
        width: 40%;
        min-width: 130px;
      }
      &-right{
        width: 60%;
        @media screen and (max-width: 475px) {
          width: 100%;
        }
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .btn-group {
          display: flex;
          gap: 12px;
          .btn-read {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 36px;
            margin: 0 auto;
            border-radius: 7px;
            font-size: 16px;
            font-weight: 600;
            line-height: 23px;
            background-color: #308DE2;
            color: #FFFFFF;
            border: none;
            text-transform: uppercase;
            cursor: pointer;
          }
          .btn-mint {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 36px;
            margin: 0 auto;
            border-radius: 7px;
            font-size: 16px;
            font-weight: 600;
            line-height: 23px;
            background-color: transparent;
            color: #FFFFFF;
            text-transform: uppercase;
            cursor: pointer;
            border-color: #B890F4;
            border-width: 2px;
            border-style: solid;
            &-closed{
              border: none;
            }
          }
        }
      }

      .title {
        h3 {
          font-size: 26px;
          font-weight: 600;
          line-height: 22px;
          margin: 0;
        }

        h1 {
          font-size: 46px;
          font-weight: 600;
          margin: 0;
          @media screen and (max-width: 475px) {
            font-size: 24px;
            line-height: 32px;
          }
        }
      }

      .copy {
        margin-top: auto;
        z-index: 1;

        p {
          font-weight: 300;
          font-size: 16px;
          padding: 0;
          margin: 22px 0;
        }

        span {
          font-weight: 300;
          color: #308DE2;
        }
      }

      .comic-book-cover {
        z-index: 1;
        min-width: 130px;
        // max-width: 85%;
        // position: absolute;
        // top: 132px;
        // right: 20px;
      }

      .lightray {
        background-image: url("../../../images/sections/hero/Light_rays1.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        opacity: 0.6;
        position: absolute;
        top: 55px;
        right: -220px;
        transform: rotate(35deg);
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 0;
      }
    }

    .mint-now {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 326px;
      height: 170px;
      padding: 25px 28px 32px;
      margin: 34px auto 0;
      background: linear-gradient(118.92deg, rgba(237, 90, 143, 0.6) 1.07%, rgba(82, 126, 239, 0.6) 99.61%), rgba(0, 0, 0, 0.65);
      box-shadow: 9px 7px 60px #0C1516, 3px 3px 1px rgba(0, 0, 0, 0.5), inset 1px 1px 0px rgba(255, 255, 255, 0.12);
      border-radius: 15px;
      text-transform: uppercase;
      text-align: left;

      h3 {
        font-size: 27px;
        line-height: 33px;
        padding-right: 90px;
        margin: 0;
      }

      button {
        padding: 15px 0;
        background: transparent;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        border-radius: 7px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        cursor: pointer;
      }
    }

  }
}

@media (min-width: 768px) {
  .hero-carousel {
    .comic-dropdown {
      margin-top: 116px;
    }

    .hero-carousel-item {
      .hero-carousel-item-inner {
        padding-left: 52px;
        align-items: center;

        .title {
          height: fit-content;
          // width: 60%;

          // h3 {
          //   font-size: 38px;
          //   line-height: 32px;
          // }

          h1 {
            font-weight: 600;
            font-size: 30px;
            line-height: 50px;
          }
        }

        .comic-book-cover {
          max-width: unset;
          width: 100%;
          margin: 0;
          // right: 30px;
          // top: 50px;
        }

        .copy {
          // width: 52%;
          // margin-top: -75px;
          
          a {
            margin: 0;
          }
        }

        .lightray {
          background-size: 440px;
          top: 0;
          right: -300px;
        }
      }

      .mint-now {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: unset;
        max-width: 1076px;
        height: 134px;

        button {
          width: 259px;
        }
      }
    }
  }
}

@media (min-width: 1080px) {
  .hero-carousel {
    .hero-carousel-item {
      max-width: unset;

      .hero-carousel-item-inner {
        padding-left: 32px;

        .copy {
          p {
            padding-right: 69px;
          }
        }
      }

      .mint-now {
        // width: 1076px;
        padding: 0;

        h3 {
          font-size: 32px;
        }
      }
    }
  }
}

.subscribe-input {
  padding-bottom: 12px;
  border-radius: 0;

  @media (min-width: 768px) {
    background-image: url("../../../images/sections/hero/email-vector.png");
    background-repeat: no-repeat;
    background-position: left 0;
    padding-left: 42px;
    padding-top: 2px;
  }
}

.swiper {
  .swiper-pagination {
    &::before {
      content: attr(data-before);
      padding: 28px 42px;
      font-weight: 500;
      font-size: 32px;
      line-height: 85.5%;
      text-transform: uppercase;
    }

    z-index: 99;
    display: flex;
    flex-direction: column;
    // width: 408px !important;
    height: fit-content !important;
    padding: 0;

    background: linear-gradient(118.92deg, rgba(237, 90, 143, 0.3) 1.07%, rgba(82, 126, 239, 0.3) 99.61%), #111219;
    box-shadow: 9px 19px 33px rgba(0, 0, 0, 0.5), inset 1px 1px 0px rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(60px);
    border-radius: 15px;

    bottom: unset !important;
    left: 50% !important;
    transform: translateX(-50%);
    top: 88px;

    .swiper-pagination-bullet {
      display: initial;
      width: 100%;
      height: fit-content;
      padding: 22px 28px;
      margin: 0;
      background: transparent;
      opacity: 1;
      text-transform: capitalize;
      border-radius: 0;
      border-top: 1px solid #5A5A5A;
      font-weight: 300;
      font-size: 26px;
      line-height: 85.5%;
      color: rgba(255, 255, 255, 0.6);
      text-align: left;
    }

    .swiper-pagination-bullet-active {
      color: #FFFFFF;

      &::after {
        content: url(../../../images/sections//hero/checked.png);
        position: absolute;
        right: 28px;
      }
    }
  }
}
