.intro-video {
  height: auto;
  width: 190%;
  max-width: unset;
  @media all and (min-width: 768px) {
    width: 150%;
  }
  @media all and (min-width: 1200px) {
    width: 100%;
  }
}

.intro-title {
  margin-top: -20vh;
  z-index: 99;
}

.features-tiles-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 58vh;
}

#armorite1,
#armorite2,
#armorite3,
#armorite4 {
  display: none;
}

.features-tiles-inner p {
  font-size: 0.9rem;
  line-height: 1rem;
}

.features-tiles-inner h4 {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4rem;
  padding: 0 1rem;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
}

#armorite1to4 {
  top: 88vw;
  right: 5vw;
}

@media screen and (min-width: 550px) {
  .features-tiles-inner {
    min-height: 65vh;
  }
}

@media screen and (min-width: 650px) {
  .features-tiles-inner {
    min-height: 75vh;
  }
}

@media screen and (min-width: 768px) {
  #armorite1,
  #armorite2,
  #armorite3,
  #armorite4 {
    display: initial;
  }

  #armorite1 {
    top: 35vw;
    right: -22vw;
    height: 20vw;
  }
  #armorite2 {
    top: 44vw;
    left: -5vw;
    height: 9vw;
  }
  #armorite3 {
    bottom: 15vw;
    right: 9vw;
    height: 12vw;
  }
  #armorite4 {
    top: 43.7vw;
    left: -11.3vw;
    height: 26.4vw;
  }

  .features-tiles-inner {
    min-height: 80vh;
  }

  .features-tiles-inner h4 {
    font-size: 1.7rem;
    line-height: 1.8rem;
  }

  .features-tiles-inner p {
    font-size: 1.1rem;
    line-height: 1rem;
  }
}

// @media screen and (min-width: 850px) {
//   .features-tiles-inner {
//     min-height: 100vh;
//   }
// }

// @media screen and (min-width: 1030px) {
//   .features-tiles-inner {
//     min-height: 110vh;
//   }
// }

// @media screen and (min-width: 1130px) {
//   .features-tiles-inner {
//     min-height: 120vh;
//   }
// }

// @media screen and (min-width: 1230px) {
//   .features-tiles-inner {
//     min-height: 130vh;
//   }
// }

// @media screen and (min-width: 1330px) {
//   .features-tiles-inner {
//     min-height: 140vh;
//   }
// }

// @media screen and (min-width: 1430px) {
//   .features-tiles-inner {
//     min-height: 150vh;
//   }
// }

// @media screen and (min-width: 1530px) {
//   .features-tiles-inner {
//     min-height: 160vh;
//   }
// }

// @media screen and (min-width: 1630px) {
//   .features-tiles-inner {
//     min-height: 170vh;
//   }
// }

// @media screen and (min-width: 1730px) {
//   .features-tiles-inner {
//     min-height: 180vh;
//   }
// }

// @media screen and (min-width: 1830px) {
//   .features-tiles-inner {
//     min-height: 190vh;
//   }
// }

// @media screen and (min-width: 1930px) {
//   .features-tiles-inner {
//     min-height: 200vh;
//   }
// }

// @media screen and (min-width: 2030px) {
//   .features-tiles-inner {
//     min-height: 210vh;
//   }
// }

// @media screen and (min-width: 2130px) {
//   .features-tiles-inner {
//     min-height: 220vh;
//   }
// }
