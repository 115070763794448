.dynasty-comp {
  cursor: pointer;
  position: relative;
  z-index: 50;
  margin-left: -4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dynasties-container {
  margin-right: -4.5vw;
}

.dynasty-img {
  position: absolute;
}

.dynasty-overlay {
  position: absolute;
  opacity: 0.6;
  pointer-events: none;
}

.dynasty-text {
  pointer-events: none;
  visibility: visible;
  text-align: center;
  opacity: 1;
  transition: visibility 0s, opacity 1s linear;
  z-index: 100;
  position: absolute;
  left: 0%;
  top: 0;
  padding: 3.6rem 1rem 0;
  height: fit-content;
  margin: 0 auto;

  .dynasty-title {
    font-size: 24px;
    font-weight: 600;
    padding: 0 1.6rem;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 6px;
    line-height: 29px;
  }

  .dynasty-subtitle {
    font-size: 20px;
    line-height: 19px;
    font-weight: 500;
    margin-bottom: 18px;
  }

  .dynasty-content {
    padding: 0 30px;
    font-size: 14px;
    line-height: 23px;
    font-weight: 500;
  }

  &#costraca {
    margin-top: -2.2rem;
  }

  @media (min-width: 360px) {
    padding-top: 5.4rem;

    .dynasty-title {
      padding: 0 2.8rem;
      margin-bottom: 16px;
    }

    .dynasty-subtitle {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .dynasty-content {
      font-size: 16px;
      padding: 0 36px;
    }

    &#costraca {
      .dynasty-title {
        padding: 0 1rem;
        margin-top: -1.2rem;
        margin-bottom: 8px;
      }
    }
  }

  @media (min-width: 380px) {
    &#costraca {
      margin-top: 0;
    }
  }

  @media (min-width: 400px) {
    padding: 5rem 1.6rem 0;
  }

  @media (min-width: 420px) {
    top: 48%;

    &#warri {
      top: 0;
    }

    &#costraca {
      .dynasty-title {
        margin-top: unset;
        margin-bottom: 24px;
      }
    }

    &#horn {
      .dynasty-title {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }
    }

    .dynasty-title {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      font-size: 32px;
      margin-bottom: 24px;
    }

    .dynasty-content {
      padding: 64px 42px 0;
    }
  }

  @media (min-width: 460px) {
    padding-top: 5rem;

    .dynasty-title {
      padding-left: 2.6rem;
      padding-right: 2.6rem;
    }
  }

  @media (min-width: 480px) {
    &#horn .dynasty-title,
    .dynasty-title {
      padding: 5rem 3.6rem 0;
    }

    &#costraca .dynasty-title {
      padding: 5rem 3rem 0;
    }

    .dynasty-content {
      padding-left: 2.8rem;
      padding-right: 2.8rem;
    }
  }

  @media (min-width: 520px) {
    &#horn {
      .dynasty-title {
        padding-left: 4.8rem;
        padding-right: 4.8rem;
      }
    }

    .dynasty-content {
      padding-top: 2.2rem;
    }
  }
}

.dynasty-comp:hover {
  z-index: 99 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .dynasty-text {
    transition-delay: 500ms;
    padding-top: 0;
    visibility: visible;
    opacity: 1;
  }
}

.dynasty-comp img {
  width: 20vw;
  height: 100%;
  transition: transform 1s;
  top: 0;
}

@media screen and (min-width: 600px) {
  .dynasty-comp:nth-child(1) .dynasty-text {
    left: 0%;
  }
  .dynasty-overlay {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  .dynasty-text {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    left: 0%;
    padding: 0 0.3rem;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    .dynasty-title {
      font-size: 0.7rem;
      padding: 0;
      line-height: 12px;
      margin-bottom: 4px;
    }

    .dynasty-subtitle {
      font-size: 0.5rem;
      margin-bottom: 24px;
    }

    .dynasty-content {
      font-size: 0.5rem;
      line-height: 0.55rem;
      padding: 0;
    }
  }

  .dynasty-comp:hover {
    .dynasty-img {
      transform: rotateY(180deg) scale(1.4, 1.2);
      transition: transform 1s;
    }
    .dynasty-overlay {
      opacity: 0.6;
      transform: rotateY(180deg) scale(1.4, 1.2);
      transition: transform 1s;
    }
  }
}

@media (min-width: 680px) {
  .dynasty-text {
    .dynasty-content {
      padding: 0 0.5rem;
    }
  }
}

@media (min-width: 900px) {
  .dynasty-text {
    .dynasty-title {
      font-size: 22px;
      line-height: 29px;
    }

    .dynasty-subtitle {
      font-size: 16px;
    }

    .dynasty-content {
      font-size: 14px;
      line-height: 14px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .dynasty-text {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    left: 0%;
    padding-left: 2vw;
    padding-right: 2vw;

    .dynasty-title {
      font-size: 32px;
      padding-left: 0;
      padding-right: 0;
      text-transform: uppercase;
      margin-bottom: 0.1rem;
      font-weight: 600;
      line-height: 93.5%;
    }
    .dynasty-subtitle {
      font-size: 20px;
      font-weight: 500;
      line-height: 93.5%;
      margin-bottom: 44.53px;
      margin-top: 13.35px;
    }
    .dynasty-content {
      font-size: 17px;
      font-weight: 400;
      line-height: 0.9rem;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
