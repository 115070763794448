.choose-container {
  display: flex;
  align-items: center;
  background: #2B293C;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }

  .desktop-bk {
    @media all and (max-width: 768px) {
      display: none;
    }
  }

  .mobile-bk {
    @media all and (min-width: 768px) {
      display: none;
    }
  }

  .string-section {

    .btn-group {
      margin-top: 72px;
      display: flex;
      gap: 12px;
      justify-content: center;
      align-items: center;
      @media all and (max-width: 1080px) {
        flex-direction: column;
      }
      @media all and (max-width: 768px) {
        margin-top: 9px;
        margin: 0 40px;
      }
    }

    @media all and (min-width: 768px) {
      transform: translateY(-15%);
      margin-left: -48%;
      margin-right: 15%;
    }
    
    @media all and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      margin-top: -125%;
      margin-bottom: 25%;
    }
  
    .title {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 600;
      font-size: 44px;
      line-height: 103.5%;
      text-transform: uppercase;
      color: #FFFFFF;

      &.disabled {
        background: grey;
      }

      @media all and (max-width: 768px) {
        font-size: 28px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.05em;
      }
    }
    
    .description {
      margin-top: 26px;
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      color: #D9D9D9;

      @media all and (max-width: 768px) {
        text-align: center;
        margin: 34px 40px;
      }
    }
  
    .action-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 72px;
      
      .choose-action {
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #308DE2;
        border-radius: 7px;
        border: none;
        line-height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.disabled {
          cursor: unset;
          background-color: grey;
        }

        @media all and (max-width: 768px) {
          width: 67%;
        }
      }
    
      .choose-description {
        @media all and (max-width: 768px) {
          margin-top: 21px;
        }
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.07em;
        text-transform: uppercase;
      }
    }
  }
  
}

.dynasties {
  width: 522px;
  height: 529px;
}

.dynasty-group-content {
  @media all and (max-width: 768px) {
    margin-right: 32px;
    margin-left: 32px;
    justify-content: center;
  }
}

.choose-dynasty-popup {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 136px;
  width: 1110px;
  height: 711px;
  background-color: #302F43;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index: 10000;
  overflow: hidden;
  @media all and (max-width: 768px) {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    top: 0px !important;
  }
  @media all and (min-width: 768px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); 
  }
  
  .content-body {
    z-index: 999;

    @media all and (min-width: 768px) {
      width: 1110px;
      height: 711px;
    }

    @media all and (max-width: 768px) {
      width: 100%;
    }
  }

  .content-bk {
    position: absolute;
    left: -40px;
    top: -40px;
    width: 488px;
    height: 730px;
    z-index: 998;

    @media all and (max-width: 768px) {
      display: none;
    }
  }

  .loading-wrapper {
    margin-top: 80px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .choose-dynasty-popup-header {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0 50px;
    background-color: transparent;
    @media all and (max-width: 768px) {
      display: none;
    }
    .back-button {
      cursor: pointer;
      align-self: center;
      color: white;
      display: flex;
      width: 25px;
      height: 25px;
      min-height: 25px;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      img {
        margin-right: 15px;
      }
    }
    .close-button {
      cursor: pointer;
      display: flex;
      align-self: end;
      width: 25px;
      height: 25px;
      min-height: 25px;
      background: url('/assets/images/close.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .choose-dynasty-popup-header-mobile {
    display: flex;
    justify-content: space-between;
    padding: 0px 30px 0px 30px;
    background-color: transparent;
    z-index: 997;
    @media all and (max-width: 768px) {
      padding: 50px 0px 0px 0px;
    }
    @media all and (min-width: 768px) {
      display: none;
    }
    .back-button {
      cursor: pointer;
      align-self: center;
      color: #d9d9d9;
      display: flex;
      min-height: 25px;
      align-items: center;
      justify-content: center;
      margin-left: -10px;
      img {
        margin-right: 15px;
      }
    }
    .close-button {
      cursor: pointer;
      display: flex;
      align-self: end;
      width: 25px;
      height: 25px;
      min-height: 25px;
      background: url('/assets/images/close.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .choose-dynasty-popup-header-mobile-verify {
    display: flex;
    justify-content: space-between;
    padding: 0px 30px 0px 30px;
    background-color: transparent;
    z-index: 997;
    margin-left: 32px;
    @media all and (max-width: 768px) {
      padding: 50px 0px 0px 0px;
    }
    @media all and (min-width: 768px) {
      display: none;
    }
    .back-button {
      cursor: pointer;
      align-self: center;
      color: #d9d9d9;
      display: flex;
      min-height: 25px;
      align-items: center;
      justify-content: center;
      margin-left: -10px;
      img {
        margin-right: 15px;
      }
    }
    .close-button {
      cursor: pointer;
      display: flex;
      align-self: end;
      width: 25px;
      height: 25px;
      min-height: 25px;
      background: url('/assets/images/close.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .dynasty-group-title {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 30px;

    @media all and (max-width: 768px) {
      margin-top: 18px;
      font-size: 31px;     
      line-height: 125.5%; 
    }

    @media all and (min-width: 768px) {
      line-height: 103.5%;
      font-size: 34px;
      text-align: center;  
    }
  }

  .dynasty-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 32px;
    padding: 97px 76px;
    justify-items: center;
    @media (max-width: 1400px) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 1200px) {
      padding: 97px 76px;
    }
    @media (max-width: 1080px) {
    }
    @media (max-width: 768px) {
      row-gap: 51px;
      padding: 50px 0px;
    }
    @media (max-width: 500px) {
      row-gap: 51px;
      padding: 50px 0px;
    }
  
    .dynasty-carousel {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      @media (min-width: 768px) {
        width: 261px;
        height: 353.91px;
      }

      @media (max-width: 768px) {
        width: 311px;
        height: 277px;
      }

      .dynasty-carousel-background {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -152px;
        width: 261px;
        height: 304px;
        @media (max-width: 768px) {
          margin-top: -122px;
          width: 311px;
          height: 227px;
        }
        background: #242335;
        border-radius: 25.7708px;
      }
      
      .dynasty-carousel-charactor {
        z-index: 10;
        width: 184.92px;
        height: 212.81px;

        @media all and (max-width: 768px) {
          width: 139px;
          height: 160px;          
        }
      }

      .dynasty-carousel-title-container {
        display: flex;
        align-items: center;
        margin-top: 152px;
        height: 80px;

        @media all and (max-width: 768px) {
          width: 100%;
          height: 18px;
          justify-content: center;    
          margin-top: 149px;      
        }
      }

      .dynasty-carousel-title {
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        line-height: 93.5%;
        width: 214px;
        
        @media all and (min-width: 768px) {
          font-size: 23.1937px;
        }
        
        @media all and (max-width: 768px) {
          font-size: 18px;
        }
      }

      .dynasty-carousel-view-dynasty {
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #308DE2;
        background-color: transparent;
        border: none;
        cursor: pointer;

        @media all and (max-width: 768px) {
          margin-top: 13px;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }   
  }
  
  .content {
    padding-right: 2rem;
    letter-spacing: 1px;
    padding: 0px 80px 0px 80px;
    display: flex;
    gap: 90px;
    @media (max-width: 1080px) {
      flex-direction: column;
      gap: 0;
      padding: 20px 30px 40px 30px;
      justify-content: flex-start;
      align-items: center;
      overflow-x: hidden;
      overflow-y: auto;
    }

    @media all and (min-width: 768px) {
      height: 541px;
    }
    
    @media all and (max-width: 768px) {
      padding: 0px 0px 45px 0px;
      width: 100%;
    }

    .content-bk-mobile {
      margin-top: -80px;
      
      @media all and (min-width: 768px) {
        display: none;      
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
    
    .left-content-detail {
      @media (min-width: 768px) {
        width: 454px;
        height: 600px;
        margin-left: -49px;
        margin-top: 44px;
        img {
          position: relative;
        }
      }
      @media (max-width: 768px) {        
        margin-left: -32px;
        margin-right: -32px;
      }
    }
    .left-content {
      display: flex;
      flex-basis: 40%;
      
      width: 40%;
      flex-direction: column-reverse;
      position: relative;
      
      img {
        position: relative;
      }
      
      @media all and (max-width: 768px) {
        margin-right: 32px;
        width: 100%;
        flex-direction: column;
      }
    }
    .right-content-detail {
      @media (min-width: 768px) {
        width: 374px;
      }
      
      @media (max-width: 768px) {        
        width: 100%;
      }
      
      .text-content {
        @media all and (min-width: 768px) {
          margin-top: 109px;
          margin-left: -72px;
          width: 540px;
        }
        @media all and (max-width: 768px) {
          margin-top: 20px;
        }

        .dynasty-detail-description {
          margin-top: 40px;
          font-family: 'Kanit';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #D9D9D9;
        }
    
        .dynasty-detail-success-description {
          font-family: 'Kanit';
          font-style: normal;
          display: flex;
          align-items: center;
          color: #D9D9D9;
          
          @media all and (min-width: 768px) {
            font-weight: 300;
            margin-top: 30px;
            font-size: 24px;
            line-height: 125.5%;
          }

          @media all and (max-width: 768px) {
            font-weight: 400;
            margin-top: 18px;
            font-size: 16px;
            line-height: 22px;
          }
        }
    
        .dynasty-detail-title {
          margin-top: 14px;
          font-family: 'Kanit';
          font-style: normal;
          text-transform: uppercase;
          color: #FFFFFF;
          
          @media all and (min-width: 768px) {
            font-weight: 600;
            font-size: 44px;
            line-height: 103.5%;
          }

          @media all and (max-width: 768px) {
            font-weight: 700;
            font-size: 31px;
            line-height: 125.5%;
          }
        }
    
        .dynasty-detail-header {
          margin-top: 30px;
          font-family: 'Kanit';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 93.5%;
          text-transform: uppercase;
          color: #FFFFFF;
        }
    
        .dynasty-detail-button-group {
          display: flex;
          gap: 24px;
          margin-top: 80px;

          @media all and (max-width: 768px) {
            flex-direction: column;
            margin-top: 56px;
          }
        }
      }      
    }
    .right-content {
      width: 60%;
      @media (max-width: 1080px) {
        width: 100%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      .text-content {
        margin-top: 57px;
        @media all and (min-width: 768px) {
          width: 100%;
        }

        @media all and (max-width: 768px) {
          margin-left: 32px;
          margin-right: 32px;
        }

        .title {
          color: #FFFFFF;
          font-family: 'Kanit';
          font-style: normal;
          font-weight: 700;
          font-size: 42px;
          line-height: 125.5%;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          
          @media all and (min-width: 768px) {
            font-size: 2rem;
          }
          
          @media all and (max-width: 768px) {
            margin-right: -32px;
            font-size: 31px;
          }
        }
        
        .description {
          margin-top: 28px;
          font-family: 'Kanit';
          font-style: normal;
          font-weight: 300;
          font-size: 24px;
          line-height: 125.5%;
          display: flex;
          align-items: center;
          color: #FFFFFF;
          @media all and (min-width: 768px) {
            font-size: 24px;
          }     
          @media all and (max-width: 768px) {
            font-size: 19px;  
            margin-top: 24px;     
          } 
        }
      }
    }
    
    form {
      margin-top: 20px;
      width: 100%;
      .submit-message {
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 300;
        font-size: 19px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        width: fit-content;
        margin-top: 13px;
        .green-check {
          width: 28px;
          height: 28px;
          border-radius: 999px;
          background: #30A450;
          margin-left: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 14px;
          }
        }
      }
      .recaptcha {
        height: 0;
        width: 0;
        overflow: hidden;
      }
      .button-wrapper {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        @media all and (max-width: 768px) {
          gap: 15px;
        }
        .create-wallet {
          margin: 0;
        }
        &.mobile-wrap {
          align-items: flex-start;
          flex-direction: column;
          gap: 0;
          button {
            margin-bottom: 20px;
          }
        }
        button {
          margin-top: 0;
        }
      }
      input,select {
        min-width: 300px;
        background-color: transparent;
        border: 1px solid #b890f4;
        border-width: 0px 0px 1px 0px;
        color: #b890f4;
        padding-left: 0px;
        @media all and (max-width: 768px) {
          min-width: unset;
          width: 100%;
        }
      }
      input::placeholder {
        color: #b890f4;
      }
      span {
        font-size: 14px;
      }
      button{
        background-color: transparent;
        border: 1px solid #b890f4;
        
        font-weight: 400;
        // width: 115px;
        margin-top: 20px;
      }
      .grecaptcha-badge {
        position: relative !important;
        margin-top: 40px !important;
        margin-bottom: 20px !important;
        bottom: 0 !important;
        left: 0;
        visibility: visible !important;
      }

      .typeSelect{
        background-color: transparent;
        color: #b890f4;
        width: 100%;
        border: 0;
        margin-top: 15px;
        margin-left: 0;
        padding-left: 0;
      }
    }

    .bottom-content {
      display: flex;
      @media (max-width: 768px) {
        margin-right: 32px;
        margin-left: 32px;
      }
      .left-content {
        flex: 40%;
        padding-left: 3rem;
        padding-bottom: 1rem;
        img {
          float:left;
          margin-top: 0px;
          @media all and (max-width: 768px) {
            width: 90%;
            float: none;
          }
        }
        @media all and (max-width: 768px) {
          padding-left: 1rem;
        }
      }
      .right-content {
        flex: 60%;
        padding-top: 20px;
      }

      .choose-input {
        height: 57px;
        @media all and (min-width: 768px) {
          width: 416px;
        }
      }

      .input-description {
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 300;
        font-size: 19px;
        line-height: 125.5%;
        color: #FFFFFF;
        margin-top: 8px;
        height: 24px;
      }      

      .create-wallet {
        margin-top: 35px;
        font-size: 20px;
        font-weight: 300;
        width: 100%;
        display: block;
        min-width: 300px;
        a {
          text-decoration: underline;
          color: #65B5FF;
          &:hover {
            color: #308DE2;
          }
          
        }
      }
    }

    .bottom-content-detail {
      display: flex;

      @media screen and (min-width: 768px) {
        margin-left: -72px;
      }
      .right-content {
        @media screen and (min-width: 768px) {
          flex: 60%;
          padding-top: 20px;
        }
      }

      
    }
  }
  .footer {
    overflow: hidden;
    background-color: #242335;
    border-radius: 0 0 12px 12px;
    padding: 42px;
    text-align: center;
    height: 105px;
    @media screen and (max-width: 768px) {
      padding: 30px;
      padding-bottom: 50px;
    }
    p {
      margin: 0;
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 300;
      font-size: 17px;
      line-height: 21px;
      @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 17px;
      }
    }
    a {
      text-decoration: underline;
      color: #65B5FF;
      &:hover {
        color: #308DE2;
      }
    }
  }
  
  .btn-group {
    display: inline-flex;
    margin-top: 29px;
    .btn-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 159px;
      height: 53px;
      margin: 0 auto;
      border-radius: 7px;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      background-color: #308DE2;
      color: #FFFFFF;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
    }
    .resend-btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 159px;
      height: 53px;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
      padding-left: 19px;
    }
    .btn-resend {
      justify-content: center;
      align-items: center;
      width: 159px;
      height: 53px;
      margin: 0 auto;
      border-radius: 7px;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: #FBFBFB;
      border: 1px solid #B890F4;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .btn-group-detail {
    @media screen and (min-width: 768px) {
      display: inline-flex;
    }
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    margin-top: 29px;
    .btn-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 7px;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      background-color: #308DE2;
      color: #FFFFFF;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
      
      @media screen and (min-width: 768px) {
        width: 200px;
        height: 53px;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        height: 41px;
      }
    }
    .resend-btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
      
      @media screen and (min-width: 768px) {
        padding-left: 19px;
        width: 200px;
        height: 53px;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        height: 41px;
        margin-top: 21px;
      }
    }
    .btn-resend {
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 7px;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: #FBFBFB;
      border: 1px solid #B890F4;
      text-transform: uppercase;
      cursor: pointer;

      @media screen and (min-width: 768px) {
        width: 200px;
        height: 53px;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        height: 41px;
      }
    }
  }
}


