.mobileMap {
  width: 100vw;
  position: absolute;
  left: 0;
  height: 820px;
  display: flex;
  background: url('../../../assets/images/sections/universe/new-map.png');
  background-repeat: no-repeat;
}

.backdrop {
  width: 100vw;
  position: absolute;
  height: 300px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 5%,
    rgba(0, 0, 0, 1) 15%
  );
  bottom: 0;
  left: 0;
}

.container {
  width: 100%;
  margin-bottom: 30px;
}

.item {
  display: flex;
  position: relative;
  height: 820px;
  cursor: pointer;
}

.mainCard {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 230px;
  width: calc(100% - 36);
  margin: 0 30px 100px;
  position: absolute;
  bottom: 0;
  padding: 25px 40px;
  background: linear-gradient(
      118.92deg,
      rgba(237, 90, 143, 0.6) 1.07%,
      rgba(82, 126, 239, 0.6) 99.61%
    ),
    rgba(0, 0, 0, 0.65);
  box-shadow: 9px 7px 60px #0c1516, 9px 19px 33px rgba(0, 0, 0, 0.65),
    inset 1px 1px 0px rgba(255, 255, 255, 0.12);
  border-radius: 15px;
}

.mainCard > h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 23.88px;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  text-align: left;
}

.mainCard > p {
  text-align: left;
  margin-top: 10px;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;
}
