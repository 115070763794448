.cta {
  .form-input {
    border-color: transparent;
    border-radius: 2px;
  }
}

.cta-inner {
  background: none;
}

.cta h3 {
  margin-bottom: -1rem;
  font-size: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 2rem;
}

@include media(">medium") {

  .cta h3 {
    font-size: 1.8rem;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 0;
  }

  .cta {
    .form-input {
      min-width: 280px;
    }
  }

  .cta-inner {
    padding-left: 28px;
    padding-right: 28px;
  }
}

.cta-inner .integrate-item-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // flex-wrap: wrap;
  @media screen and (max-width: 850px) {
    flex-direction: column;
}
}

.cta-inner .integrate-item-list .integrate-item:nth-of-type(1) img {
  height: 25vh;
  //margin-top: 0.5rem;
  margin-bottom: -2.5rem;
  padding-top: 1rem;
}
.cta-inner .integrate-item-list .integrate-item:nth-of-type(2) img {
  margin-bottom: -3rem;
  
}

.cta-inner .integrate-item-list .integrate-item:nth-of-type(3) img {
  margin-bottom: -3rem;
}

.cta-inner .integrate-item-list .integrate-item img {
  width: 25vh;
  height: auto;
}

.cta-inner .integrate-item-list .integrate-item {
  height: 0;
  width: 100%;
  padding-bottom: 31vh;
  background: linear-gradient(
      133.83deg,
      rgba(255, 128, 219, 0.13) 1.7%,
      rgba(82, 106, 192, 0.13) 53.92%,
      rgba(82, 229, 239, 0.13) 109.64%
    ),
    rgba(255, 255, 255, 0.05);
  box-shadow: 9px 19px 33px rgba(0, 0, 0, 0.65), 3px 3px 1px rgba(0, 0, 0, 0.5),
    inset 1px 1px 0px rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.cta-inner .integrate-item-list .integrate-item h6 {
  font-size: 24px;
  line-height: 30.12px;
  padding-bottom: 0.2rem;
  margin-bottom: 0;
}

.cta-inner .integrate-item-list .integrate-item p {
  font-size: 0.7rem;
  line-height: 0.8rem;
  margin-bottom: 0;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  text-align: center;
}

@media screen and (min-width: 600px){
  .cta-inner {
    background: url("../../../images/sections/integrate/BackLine.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
